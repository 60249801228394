import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import SectionBlock from "./Draggable/Block/SectionBlock";
import ButtonBlock from "./Draggable/Block/ButtonBlock";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixHigh";
import { useLocation, useNavigate } from "react-router-dom";

import TextBlock from "./Draggable/Block/TextBlock";
import ImageBlock from "./Draggable/Block/ImageBlock";
import InputBlock from "./Draggable/Block/InputBlock";
import BirthDayBlock from "./Draggable/Block/BirthDayBlock";
import CouponCodeBlock from "./Draggable/Block/CouponCodeBlock";
import { useApp } from "../../context/AppState";
import { useAction } from "../../hooks/useAction";
import { useAnalytics } from "../../hooks/useAnalytics";
import DraggableWrapper from "../Draggable";
import {
  DRAGGABLE_POPUP_CLOSE_BEHAVIOR,
  POPUP_SECTIONS,
  settingIndex,
} from "../../constant/constant";
import { defaultDraggablePopupStates } from "../../pages/popup/DefaultPopupStates";
import {
  analyticsEventTypes,
  getDraggablePopupBackgroundStyle,
  sentPostMessage,
} from "../../helper";
import TimerBlock from "./Draggable/Block/TimerBlock";
import ProductBlock from "./Draggable/Block/ProductBlock";
import RatingInputBlock from "./Draggable/Block/RatingInputBlock";
import VideoBlock from "./Draggable/Block/VideoBlock";
import CountdownBlock from "./Draggable/Block/CountdownBlock";
import SliderBlock from "./Draggable/Block/SliderBlock";
import CustomHtmlBlock from "./Draggable/Block/CustomHtmlBlock";
import ChoiceBlock from "./Draggable/Block/ChoiceBlock";

const RenderDraggablePopup = ({ popupData, popupState, setPopupState }) => {
  const { appState, isMobile, addToCartEvents } = useApp();
  const popupRef = useRef(null);
  const popupOverlayRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { addAnalyticsEvent } = useAnalytics();

  const activeStep = useMemo(() => popupState?.activeStep, [popupState]);
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);

  const [selectedBlock, setSelectedBlock] = useState({});

  window.addEventListener("message", (event) => {
    if (event.data.event === "sectionCreated") {
      const { type, index } = event.data;
      setSelectedBlock({ type, index });
      handleBlockClick(type, index);
    }
  });

  const handleBlockClick = (type, index) => {
    if (appState && appState?.is_preview_mode) {
      if (selectedBlock.type !== type || selectedBlock.type !== index) {
        sentPostMessage("select_block", {
          index,
        });
        setSelectedBlock({ type, index });
      }
    }
  };
  useEffect(() => {
    if (window?.isLiveModeTrigger) return;
    if (!popupOverlayRef.current) return;

    if (selectedBlock && Object.keys(selectedBlock).length === 0) {
      popupOverlayRef.current.style.opacity = "1";
    } else {
      popupOverlayRef.current.style.opacity = "0";
    }
  }, [selectedBlock]);

  const handleOutSideBlockClick = () => {
    setSelectedBlock({});
    if (appState && appState?.is_preview_mode) {
      sentPostMessage("select_block", { index: settingIndex });
    }
  };

  const openSettingBar = (index) => {
    setSelectedBlock({});
    if (appState && appState?.is_preview_mode) {
      sentPostMessage("select_block", {
        index,
      });
    }
  };

  const handleClose = useCallback(
    async (callAddToCartEvents = true) => {
      const defaultState = defaultDraggablePopupStates[popupType];

      setPopupState((prev) => ({
        ...prev,
        [`${popupType}_${popupId}`]: {
          activeStep: 2,
          openPopup: true,
          ...(defaultState && defaultState),
          ...(shouldClosePopup(popupType) && {
            openPopup: false,
          }),
        },
      }));

      if (location?.hash !== "") {
        navigate(`${location?.pathname}`);
      }

      if (popupData?.popUpType && popupData?.popupId) {
        await addAnalyticsEvent({
          eventType: analyticsEventTypes.CLOSE,
          popupType: popupData?.popUpType,
          popupId: popupData?.popupId,
          ...(popupData?.campaignId && { campaignId: popupData?.campaignId }),
        });
      }

      if (
        popupData?.generalSettings.condition.product.onCartAdd &&
        popupData?.generalSettings?.condition?.product
          ?.onCartAddEventPrevented &&
        callAddToCartEvents
      ) {
        setTimeout(() => {
          addToCartEvents();
        }, 100);
      }
    },
    [setPopupState, location, navigate, popupType, popupId]
  );

  const sectionsSwitch = useCallback(
    (section, index) => {
      switch (section?.type) {
        case POPUP_SECTIONS.SECTION:
          return <SectionBlock section={section} />;
        case POPUP_SECTIONS.CUSTOM_HTML:
          return <CustomHtmlBlock section={section} />;
        case POPUP_SECTIONS.CHOICE:
          return <ChoiceBlock section={section} />;
        case POPUP_SECTIONS.TITLE:
        case POPUP_SECTIONS.SUBTITLE:
        case POPUP_SECTIONS.PARAGRAPH:
        case POPUP_SECTIONS.CODE_SUMMARY:
        case POPUP_SECTIONS.TEXT:
          return <TextBlock section={section} />;
        case POPUP_SECTIONS.COVER_IMAGE:
          return (
            <ImageBlock
              section={section}
              setPopupState={setPopupState}
              handleClose={handleClose}
              popupData={popupData}
            />
          );
        case POPUP_SECTIONS.VIDEO:
          return <VideoBlock section={section} />;
        case POPUP_SECTIONS.BUTTON:
          return (
            <ButtonBlock
              popupData={popupData}
              popupState={popupState}
              setPopupState={setPopupState}
              section={section}
              handleClose={handleClose}
            />
          );
        case POPUP_SECTIONS.INPUT:
          return <InputBlock section={section} />;
        case POPUP_SECTIONS.BIRTHDAY:
          return section?.text && <BirthDayBlock section={section} />;
        case POPUP_SECTIONS.CODE:
          return <CouponCodeBlock section={section} />;
        case POPUP_SECTIONS.COUNTDOWN:
          return <CountdownBlock popupData={popupData} section={section} />;
        case POPUP_SECTIONS.RATING:
          return <RatingInputBlock popupData={popupData} section={section} />;
        case POPUP_SECTIONS.VIDEO_SLIDER:
          return (
            <SliderBlock
              popupData={popupData}
              section={section}
              handleClose={handleClose}
            />
          );
        case POPUP_SECTIONS.PRODUCT:
          return (
            <ProductBlock
              popupData={popupData}
              popupState={popupState}
              setPopupState={setPopupState}
              section={section}
              activeStep={activeStep}
              index={index}
              handleClose={handleClose}
            />
          );
        default:
          return null;
      }
    },
    [popupData, popupState, setPopupState, activeStep]
  );

  const [maxDimensions, setMaxDimensions] = useState({ width: 0, height: 0 });

  const width =
    popupData?.schema[activeStep]?.popupSettings?.customCSS?.[
      isMobile ? "mobile" : "desktop"
    ]?.width ?? 0;
  const height =
    popupData?.schema[activeStep]?.popupSettings?.customCSS?.[
      isMobile ? "mobile" : "desktop"
    ]?.height ?? 0;

  // const widthUnit =  popupData?.schema[activeStep]?.popupSettings?.customCSS?.[
  //   isMobile ? "mobile" : "desktop"
  // ]?.widthUnit ?? "px";
  // const heightUnit =  popupData?.schema[activeStep]?.popupSettings?.customCSS?.[
  //   isMobile ? "mobile" : "desktop"
  // ]?.heightUnit ?? "px";

  useEffect(() => {
    const childDimensions = popupData?.schema[activeStep]?.sections.map(
      (section) => ({
        width:
          section?.setting?.dimension?.[isMobile ? "mobile" : "desktop"]
            ?.width ?? 0,
        height:
          section?.setting?.dimension?.[isMobile ? "mobile" : "desktop"]
            ?.height ?? 0,
      })
    );

    let maxWidth = 0;

    let maxHeight = 0;
    if (childDimensions) {
      maxWidth = Math.max(...childDimensions?.map((dim) => dim.width));
      maxHeight = Math.max(...childDimensions?.map((dim) => dim.height));
    }

    setMaxDimensions({
      width: width > 0 ? width : maxWidth,
      height: height > 0 ? height : maxHeight,
    });
  }, [popupData, width, height, activeStep, isMobile]);

  /// popup close

  const shouldClosePopup = (type) => {
    return !!DRAGGABLE_POPUP_CLOSE_BEHAVIOR.CLOSE[type];
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleOutSideBlockClick();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutSideBlockClick]);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (popupData && popupState) {
      setIsLoaded(true);
    }
  }, [popupData, popupState]);

  const renderDraggablePopup = useMemo(() => {
    if (!isLoaded) return null;
    return (
      <>
        {!window?.isLiveModeTrigger && (
          <div
            className="hover-overlay"
            style={{
              position: "absolute",
              boxSizing: "border-box",
              zIndex: 9999,
              opacity: 0,
              borderWidth: 4,
              borderStyle: "solid",
              borderColor: "#3A2C6F",
              borderRadius: 0,
              transition: "all 0.3s ease-in-out",
              background: "transparent",
              backgroundColor: "transparent",
              pointerEvents: "none",
              ...maxDimensions,
              transform: `translate(calc(-50%), calc(-50% )) translate(${
                maxDimensions.width / 2
              }px, ${maxDimensions.height / 2}px) scale(${
                isMobile ? 1.04 : 1.1
              })`,
            }}
            ref={popupOverlayRef}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "-4px",
                transform: "translateY(-100%)",
                background: "#3A2C6F",
                color: "#fff",
                padding: "6px 12px",
                borderTopRightRadius: "6px",
                fontSize: "12px",
                whiteSpace: "nowrap",
                zIndex: 9999,
              }}
            >
              Edit Mode
            </div>
          </div>
        )}
        <div
          style={{
            pointerEvents: "all",
            position: "absolute",
            boxSizing: "border-box",
            ...popupData?.schema[activeStep]?.popupSettings?.customCSS?.[
              isMobile ? "mobile" : "desktop"
            ],
            ...(popupData?.schema?.[activeStep]?.popupSettings?.customCSS?.[
              isMobile ? "mobile" : "desktop"
            ]?.hasOwnProperty("showBackground")
              ? !popupData.schema[activeStep].popupSettings.customCSS[
                  isMobile ? "mobile" : "desktop"
                ].showBackground
                ? {
                    background: "transparent",
                    backgroundColor: "transparent",
                  }
                : {}
              : {}),
            ...maxDimensions,
            // width: `${maxDimensions?.width}${widthUnit ?? 'px'}`,
            // height: `${maxDimensions?.height}${heightUnit ?? 'px'}`,
            transform: `translate(-50%, -50%) translate(${
              maxDimensions.width / 2
            }px, ${maxDimensions.height / 2}px)`,
          }}
          className="popup-block-wrapper"
          ref={popupRef}
        >
          <div
            style={{
              ...getDraggablePopupBackgroundStyle(
                popupData?.schema[activeStep]?.popupSettings,
                isMobile
              ),
              ...(popupData?.schema?.[activeStep]?.popupSettings?.customCSS?.[
                isMobile ? "mobile" : "desktop"
              ]?.hasOwnProperty("showBackground")
                ? !popupData.schema[activeStep].popupSettings.customCSS[
                    isMobile ? "mobile" : "desktop"
                  ].showBackground
                  ? {
                      background: "transparent",
                      backgroundColor: "transparent",
                    }
                  : {}
                : {}),
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 0,
              borderRadius:
                popupData?.schema[activeStep]?.popupSettings?.customCSS?.[
                  isMobile ? "mobile" : "desktop"
                ]?.borderRadius,
            }}
          />

          {appState?.is_preview_mode && (
            <div
              style={{
                width: "100%",
                display: "flex",
                position: "absolute",
                cursor: "pointer",
                zIndex: 999,
              }}
              onClick={() => openSettingBar(settingIndex)}
            >
              <div
                style={{
                  position: "absolute",
                  right: 5,
                  top: -40,
                }}
              >
                <AutoFixNormalIcon />
              </div>
            </div>
          )}

          {popupData?.schema[activeStep]?.sections?.map((section, index) => {
            return (
              <DraggableWrapper
                key={index}
                index={index}
                popupSettings={popupData?.schema[activeStep]?.popupSettings}
                section={section}
                isSelected={
                  section?.type === selectedBlock?.type &&
                  index === selectedBlock?.index
                }
                onClick={() => handleBlockClick(section?.type, index)}
                lockDraggable={popupData?.generalSettings?.lockDraggable}
              >
                {sectionsSwitch(section, index)}
              </DraggableWrapper>
            );
          })}
        </div>
      </>
    );
  }, [
    isLoaded,
    popupData,
    popupState,
    selectedBlock,
    sectionsSwitch,
    handleBlockClick,
    getDraggablePopupBackgroundStyle,
    activeStep,
    appState?.is_preview_mode,
  ]);

  return <>{renderDraggablePopup}</>;
};

export default RenderDraggablePopup;
