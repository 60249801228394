import React, { useEffect, useMemo, useState } from "react";
import { useApp } from "../../context/AppState";
import {
  getOverlayStyle,
  getDraggablePopupAnimationAndShadowStyles,
  getDraggablePopupPositionStyles,
  isValidEventTime,
} from "../../helper";
import { Box } from "@mui/material";
import { FormProvider } from "../FormProvider";
import { useForm } from "react-hook-form";
import RenderDraggablePopup from "./RenderDraggablePopup";

const RenderDynamicPopupPreview = ({
  popupData,
  popupState,
  setPopupState,
}) => {
  const { isMobile, appState } = useApp();

  const [showPopup, setShowPopup] = useState(false);

  const activeStep = useMemo(() => popupState?.activeStep, [popupState]);

  const popupSettings = useMemo(
    () => popupData?.schema[activeStep]?.popupSettings,
    [popupData, activeStep]
  );

  const embeddingData = useMemo(
    () => popupData?.generalSettings?.condition?.embed,
    [popupData]
  );

  const rootStyles = {
    position: embeddingData?.is_embedded ? "absolute" : "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents:
      popupSettings?.dynamicalOptions?.overlay_style === "None"
        ? "none"
        : "all",
    zIndex: embeddingData?.is_embedded ? 1 : 999999999999999,
  };

  const overlayStyles = useMemo(
    () => ({
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      ...getOverlayStyle(popupSettings?.dynamicalOptions?.overlay_style),
    }),
    [
      popupSettings?.dynamicalOptions?.isHideBgOverlay,
      popupSettings?.dynamicalOptions?.overlay_style,
    ]
  );

  const relativeStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    padding: `${
      popupData?.schema?.[popupState?.activeStep]?.popupSettings
        ?.dynamicalOptions?.window?.padding?.[
        isMobile ? "mobile" : "desktop"
      ] || 0
    }px`,
    ...getDraggablePopupPositionStyles(
      popupSettings?.dynamicalOptions,
      isMobile
    ),
    ...getDraggablePopupAnimationAndShadowStyles(
      popupSettings?.dynamicalOptions,
      isMobile
    ),
  };

  const methods = useForm({
    defaultValues: {},
    mode: "onSubmit",
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    if (appState?.is_preview_mode) {
      setShowPopup(true);
      return;
    }

    const { eventStartTime, eventEndTime, isTimerPopup } =
      popupData?.generalSettings?.condition?.timer || {};

    const { countdownEndTime } =
      popupData?.generalSettings?.condition?.countdown?.values || {};

    const checkTime = () => {
      const currentTime = Date.now();

      if (isTimerPopup) {
        const isEventTimeValid = isValidEventTime(eventStartTime, eventEndTime);
        if (!isEventTimeValid) {
          setShowPopup(false);
          return;
        }

        if (countdownEndTime) {
          const countdownTime = new Date(countdownEndTime).getTime();
          setShowPopup(currentTime < countdownTime);
        } else {
          setShowPopup(true);
        }
      } else if (countdownEndTime) {
        const countdownTime = new Date(countdownEndTime).getTime();
        setShowPopup(currentTime < countdownTime);
      } else {
        setShowPopup(true);
      }
    };

    checkTime();

    const intervalId = setInterval(checkTime, 1000);
    return () => clearInterval(intervalId);
  }, [
    popupData?.generalSettings?.condition?.timer,
    popupData?.generalSettings?.condition?.countdown?.values?.countdownEndTime,
    appState?.is_preview_mode,
  ]);

  if (!showPopup) return <></>;

  return (
    <FormProvider
      popupData={popupData}
      popupState={popupState}
      methods={methods}
      onSubmit={handleSubmit(() => {})}
    >
      <div style={rootStyles}>
        <div style={overlayStyles} />
        <Box key={activeStep} sx={relativeStyles}>
          <RenderDraggablePopup
            popupData={popupData}
            popupState={popupState}
            setPopupState={setPopupState}
          />
        </Box>
      </div>
    </FormProvider>
  );
};

export default RenderDynamicPopupPreview;
