import React, { memo } from "react";
import { parseCssStringToObject } from "../../../../helper";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";

const ChoiceBlock = ({ section }) => {
  const { isMobile } = useApp();

  const containerCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  return (
    <div style={containerCSS}>
      <input
        type="radio"
        id={`radio-${section?.value}`}
        name={section?.name}
        value={section?.value}
        style={{
          height: section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"]
            ?.fontSize,
          width : section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"]
            ?.fontSize
        }}
      />
      <label
        htmlFor={`radio-${section?.value}`}
        dangerouslySetInnerHTML={{
          __html: section?.content ?? "",
        }}
      />
    </div>
  );
};

export default memo(ChoiceBlock);
