import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useApp } from "../../../../context/AppState";
import { parseCssStringToObject } from "../../../../helper"; // Assuming this helper is available
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";

const QuantityPickerBlock = ({ section, index, productIndex }) => {
  const { control, setValue, getValues } = useFormContext();
  const { isMobile } = useApp();

  const name = `${section?.type}-${productIndex}`;

  // Dynamic CSS for the block (similar to how it's done in TextBlock)
  const blockCSS = {
    // border: "0.5px solid lightgray",
    // borderRadius: "5px",
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
    display: "flex",
    flexWrap: "none"
  };
  const buttonStyle = {
    background: "transparent",
    borderWidth: 0,
    cursor: "pointer",
    transition: "all 100ms ease",
  };
  const hoverStyle = {
    backgroundColor: "#f0f0f0",
    color: "#333",
  };

  const handleIncrement = () => {
    const currentValue = getValues(name);
    if (section?.max) {
      if (currentValue < section?.max) {
        setValue(name, currentValue + 1);
      }
    } else {
      setValue(name, currentValue + 1);
    }
  };

  const handleDecrement = () => {
    const currentValue = getValues(name);
    if (section?.min) {
      if (currentValue > section?.min) {
        setValue(name, currentValue - 1);
      }
    } else {
      if (currentValue > 1) {
        setValue(name, currentValue - 1);
      }
    }
  };

  return (
    <div
      style={{
        ...blockCSS,
      }}
    >
      {/* Decrement Button */}
      <button
        onClick={handleDecrement}
        style={{
          ...blockCSS, // Allow additional button-specific styles
          ...buttonStyle,
          background: "transparent",
          borderWidth: 0,
          cursor: "pointer",
          borderTopRightRadius:"0",
          borderBottomRightRadius:"0",
        }}
        onMouseEnter={(e) => {
          e.target.style.background = hoverStyle.backgroundColor;
          e.target.style.color = hoverStyle.color;
        }}
        onMouseLeave={(e) => {
          e.target.style.background = buttonStyle.background;
          e.target.style.color = "";
        }}
      >
        -
      </button>

      {/* Quantity Control */}
      <Controller
        name={name}
        control={control}
        defaultValue={1}
        render={({ field }) => (
          <input
            {...field}
            value={field?.value || 1}
            disabled
            style={{
              ...blockCSS,
              background: "transparent",
              borderWidth: 0,
              borderRadius:"0",
              textAlign: "center",
            }}
          />
        )}
      />

      {/* Increment Button */}
      <button
        onClick={handleIncrement}
        style={{
          ...blockCSS,
          ...buttonStyle,
          background: "transparent",
          borderWidth: 0,
          borderTopLeftRadius:"0",
          borderBottomLeftRadius:"0",
          cursor: "pointer",
        }}
        onMouseEnter={(e) => {
          e.target.style.background = hoverStyle.backgroundColor;
          e.target.style.color = hoverStyle.color;
        }}
        onMouseLeave={(e) => {
          e.target.style.background = buttonStyle.background;
          e.target.style.color = "";
        }}
      >
        +
      </button>
    </div>
  );
};

export default QuantityPickerBlock;
