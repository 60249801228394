import axios from "axios";
import { api } from "../utils/interceptor";

const storeName = window?.Shopify?.shop || window.location.hostname;

const addToCart = async (items) => {
  // try {
    const response = await fetch(`${window?.Shopify?.routes?.root}cart/add.js`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items, sections: "cart-drawer,cart-icon-bubble"}),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();


    // const cartDrawerHtml = data.sections['cart-drawer'];

    // // Create a temporary DOM element to parse the cartDrawerHtml
    // const tempDiv = document.createElement('div');
    // tempDiv.innerHTML = cartDrawerHtml; // Set the inner HTML

    // // Extract the new CartDrawer, footer, styles, and scripts
    // const newCartForm = tempDiv.querySelector('#CartDrawer-Form');
    // const newFooter = tempDiv.querySelector('.drawer__footer');

    // // Update the original CartDrawer div with the new content
    // const originalCartForm = document.getElementById('#CartDrawer-Form');
    // if (originalCartForm && originalCartForm) {
    //   originalCartForm.innerHTML = newCartForm.innerHTML;
    // }

    // // Update the footer if it exists
    // const originalFooter = document.querySelector('.drawer__footer');
    // if (originalFooter && newFooter) {
    //   originalFooter.innerHTML = newFooter.innerHTML; 
    // }

    // window.location.href = "/cart";

    return data;
  // } catch (error) {
  //   console.error('Error adding to cart:', error);
  // }
};

const getIp = () => { return axios.get("https://api.ipify.org?format=json"); };

const addToCartGraphql = async (items) => {
  try {
    const responseIp = await getIp();
    const response = await api.post(
      `/api/public/cart`,
      { items, storeName, ip: responseIp?.data?.ip }
    );

    return response?.data;
  } catch (error) {
    console.log("Error in adding account", error);
  }
};

const getCheckoutUrl = async (items) => {
  try {

    const responseIp = await getIp();

    const response = await api.post(
      `/api/public/checkout`,
      { items, storeName, ip: responseIp?.data?.ip }
    );

    return response?.data;
  } catch (error) {
    console.log("Error in adding account", error);
  }
};

const cartService = {
  addToCart,
  // getCheckoutUrl,
  getCheckoutUrl
}


export default cartService;

