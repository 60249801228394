export const HOST_URL = process.env.REACT_APP_AWS_CONFIG_URL;

export const POPUP_SECTIONS = {
  TITLE:"TITLE",
  DESCRIPTION:"DESCRIPTION",
  BADGE:"BADGE",
  RATING:"RATING",
  PRICE:"PRICE",
  VARIANT_PICKER:"VARIANT_PICKER",
  QUANTITY_PICKER:"QUANTITY_PICKER",
  COVER_IMAGE: "COVER_IMAGE",
  PRODUCT: "PRODUCT",
  SECTION: "SECTION",
  TITLE: "TITLE",
  TEXT: "TEXT",
  SUBTITLE: "SUBTITLE",
  PARAGRAPH: "PARAGRAPH",
  VIDEO: "VIDEO",
  VIDEO_SLIDER:"VIDEO_SLIDER",
  BUTTON: "BUTTON",
  BUTTON1: "BUTTON1",
  INPUT: "INPUT",
  DISCOUNT_CODE: "DISCOUNT_CODE",
  SHOP_BUTTON: "SHOP_BUTTON",
  TIMER: "TIMER",
  INSTRUCTION: "INSTRUCTION",
  COUPON_CODE: "COUPON_CODE",
  CODE_SUMMARY: "CODE_SUMMARY",
  CODE: "CODE",
  CONDITION: "CONDITION",
  LIST1: "LIST1",
  LIST2: "LIST2",
  DISCOUNT_TYPE: "DISCOUNT_TYPE",
  DISCOUNT_VALUE: "DISCOUNT_VALUE",
  SAVINGLABLE: "SAVINGLABLE",
  GIVE_DISCOUNT: "GIVE_DISCOUNT",
  QUANTITY: 'QUANTITY',
  SHIPPING: 'SHIPPING',
  SHIPPING_RATE: 'SHIPPING_RATE',
  CHARGE_PER_UNIT: "CHARGE_PER_UNIT",
  VERIFICATION: "VERIFICATION",
  PRODUCT_TITLE: 'PRODUCT_TITLE',
  BIRTHDAY: "BIRTHDAY",
  BADGE_1: "BADGE_1",
  BADGE_2: 'BADGE_2',
  STAR: 'STAR',
  OFFER_PRODUCT: 'OFFER_PRODUCT',
  COUNTDOWN:"COUNTDOWN",
  CUSTOM_HTML:"CUSTOM_HTML",
  CHOICE:"CHOICE",
  SUBSCRIPTION:"SUBSCRIPTION",
};

export const POPUP_TYPES = {
  WELCOME: "WELCOME",
  WHEELSPIN: "WHEELSPIN",
  POPUP_UPSELL: "POPUP_UPSELL",
};

export const POPUP_DEFAULT_IMAGE = {
  "Welcome Discount Popup": "../../assets/img/template_popup.png",
  WELCOME: "../../assets/img/template_popup.png",
  countdown_timer: "../../assets/img/template_popup.png",
  Spin_wheel: "../../assets/img/template_popup.png",
};

export const TEMPLATE_TYPE = {
  COUNTDOWN_TIMER: "Countdown Timer",
  WELCOME_POPUP: "Welcome Discount Popup",
  SPIN_WHEEL: "Spin Wheel",
  UPSELL_CHECKOUT: "Upsell Checkout Page",
  FLASH_CHECKOUT:"Flash Checkout Popup",
  SOCIAL_PROOF: "Social Proof",
  MOST_SELLOUT_POPUP: "Most Sellout Popup",
  WELCOME_VERTICAL_POPUP: "Welcome Vertical Popup",
  TEMP_WELCOME_VERTICAL_POPUP: "Temp Welcome Vertical Popup",
  BUNDLE_DISCOUNT_POPUP: "Bundle Discount",
  VOLUME_DISCOUNT_POPUP: "Volume Discount",
  MULTI_BOARD_POPUP: "Multi Board",
  FEEDBACK_REVIEW_POPUP: "Feedback Review",
  BOOK_APPOINTMENT_POPUP: "Book Appointment",
  MICROSOFT_TEAMS: "Microsoft Teams",
  WHATSAPP_CHAT: "Whatsapp Chat",
  HALLOWEEN_DISCOUNT: "Halloween Discount",
  HALLOWEEN_LIMITED_SALE: "Halloween Limited Sale",
  HALLOWEEN_OFFER_POPUP: "Halloween Offer Popup",
  BLACK_FRIDAY_DISCOUNT: "Black Friday Discount",
  BLACK_FRIDAY_LIMITED_TIME: "Black Friday Limited Time",
  BLACK_FRIDAY_OFFER: "Black Friday Offer",
  CHRISTMAS_LIMITED_TIME: "Christmas Limited Time",
  CHRISTMAS_OFFER: "Christmas Offer",
  CHRISTMAS_DISCOUNT: "Christmas Discount",
  NEW_YEAR_DISCOUNT: "New Year Discount",
  YEAR_END_SALE: "Year End Sale",
  NEW_YEAR_LIMITED_TIME: "New Year Limited Time",
  DIWALI_OFFER: "Diwali Offer",
  DIWALI_SAVINGS: "Diwali Savings",
  DIWALI_LIMITED_TIME: "Diwali Limited Time",
  TWO_STEP_POPUP:"Two Step Popup",
  CUSTOM_POPUP: "Custom Popup",
  CHECKOUT_EXTENSION_UPSELL_WIDGET: "checkout_extension_upsell_widget",
};

export const POPUP_CONFIG = {
  [TEMPLATE_TYPE.WELCOME_VERTICAL_POPUP]: [0, 1],
  [TEMPLATE_TYPE.TEMP_WELCOME_VERTICAL_POPUP]: [0, 1, 2],
  [TEMPLATE_TYPE.WELCOME_POPUP]: [0, 1],
  [TEMPLATE_TYPE.FLASH_CHECKOUT]: [0, 1],
  [TEMPLATE_TYPE.CHECKOUT_EXTENSION_UPSELL_WIDGET]: [0, 1],
  [TEMPLATE_TYPE.UPSELL_CHECKOUT]: [0],
  [TEMPLATE_TYPE.SPIN_WHEEL]: [0, 1],
  [TEMPLATE_TYPE.SOCIAL_PROOF]: [0, 1],
  [TEMPLATE_TYPE.MOST_SELLOUT_POPUP]: [0, 1],
  [TEMPLATE_TYPE.BUNDLE_DISCOUNT_POPUP]: [0],
  [TEMPLATE_TYPE.VOLUME_DISCOUNT_POPUP]: [0],
  [TEMPLATE_TYPE.MULTI_BOARD_POPUP]: [0],
  [TEMPLATE_TYPE.COUNTDOWN_TIMER]: [0, 1, 2],
  [TEMPLATE_TYPE.FEEDBACK_REVIEW_POPUP]: [0, 1],
  [TEMPLATE_TYPE.BOOK_APPOINTMENT_POPUP]: [0],
  [TEMPLATE_TYPE.MICROSOFT_TEAMS]: [0],
  [TEMPLATE_TYPE.WHATSAPP_CHAT]: [0],
  [TEMPLATE_TYPE.HALLOWEEN_DISCOUNT]: [0, 1],
  [TEMPLATE_TYPE.BLACK_FRIDAY_DISCOUNT]: [0, 1],
  [TEMPLATE_TYPE.HALLOWEEN_OFFER_POPUP]: [0, 1],
  [TEMPLATE_TYPE.BLACK_FRIDAY_OFFER]: [0, 1],
  [TEMPLATE_TYPE.CHRISTMAS_OFFER]: [0, 1],
  [TEMPLATE_TYPE.YEAR_END_SALE]: [0, 1],
  [TEMPLATE_TYPE.DIWALI_OFFER]: [0, 1],
  [TEMPLATE_TYPE.HALLOWEEN_LIMITED_SALE]: [0, 1, 2],
  [TEMPLATE_TYPE.BLACK_FRIDAY_LIMITED_TIME]: [0, 1, 2],
  [TEMPLATE_TYPE.NEW_YEAR_LIMITED_TIME]: [0, 1, 2],
  [TEMPLATE_TYPE.CHRISTMAS_LIMITED_TIME]: [0, 1, 2],
  [TEMPLATE_TYPE.DIWALI_LIMITED_TIME]: [0, 1, 2],
  [TEMPLATE_TYPE.TWO_STEP_POPUP]: [0, 1],
  [TEMPLATE_TYPE.CUSTOM_POPUP]: [0],
};

export const POPUP_CLOSE_BEHAVIOR = {
  CLOSE: {
    SOCIAL_PROOF: true,
    MOST_SELLOUT_POPUP: true,
  },
};

export const DRAGGABLE_POPUP_CLOSE_BEHAVIOR = {
  CLOSE: {
    [TEMPLATE_TYPE.SOCIAL_PROOF]: true,
    [TEMPLATE_TYPE.MOST_SELLOUT_POPUP]: true,
    [TEMPLATE_TYPE.CUSTOM_POPUP]: true,
  },
};


export const  DEFAULT_BLOCK_CSS = {
  position: 'relative',
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
  alignItems:"center",
  boxSizing : "border-box",
  margin: 0,
  padding: 0,
  height: '100%',
  width: "100%",
  background: "transparent",
  color: "black",
  fontSize : "16px",
  outline: "none",
  appearance: "none",
  border: "none",
  textAlign: "center",
  lineHeight:"normal",
  boxShadow:"unset",
  resize: "none", 
}

export const settingIndex = -123;
