import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import {
  analyticsEventTypes,
  parseCssStringToObject,
} from "../../../../helper";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import cartService from "../../../../services/cart.service";
import { useAnalytics } from "../../../../hooks/useAnalytics";
import Loader from "../Loader";
import getSymbolFromCurrency from "currency-symbol-map";
import ViewTabIcon from "../../../../assets/noun-new-tab-6294363.svg";

const SliderBlock = ({ popupData, section, handleClose }) => {
  const storeName = (window?.Shopify?.shop || window.location.hostname).replace('.myshopify.com', '');
  const { isMobile, appState } = useApp();
  const currencySymbol = appState?.is_preview_mode
  ? getSymbolFromCurrency(appState?.shop?.currency)
  : getSymbolFromCurrency(window?.Shopify?.currency?.active);

  const [isLoading, setIsLoading] = useState(false);
  const swiperRef = useRef(null);
  const { addAnalyticsEvent } = useAnalytics();
  const [activeIndex, setActiveIndex] = useState(0);
  const videos = section.sections || [];
  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const videoRefs = useRef([]);
  
  useEffect(() => {
    requestAnimationFrame(() => {
      videoRefs.current.forEach((video, index) => {
        if (video) {
          if (index === activeIndex) {
            video
              .play()
              .catch((error) => console.warn("Video play failed:", error));
          } else {
            video.pause();
          }
        }
      });
    });
  }, [activeIndex]);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  // const handleSubmitCart = async (product) => {
  //   setIsLoading(true);
  //   try {
  //     let items = [
  //       {
  //         id: product?.variants?.[0]?.id,
  //         quantity: 1,
  //       },
  //     ];

  //     const result = await cartService.addToCart(items);
  //     if (result) {
  //       await addAnalyticsEvent({
  //         eventType: analyticsEventTypes.INTERACTION,
  //         popupType: popupData?.popUpType,
  //         popupId: popupData?._id,
  //         ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
  //       });
  //       setIsLoading(false);
  //       handleClose();
  //     }else{
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log(error);
  //   }
  // };
  return (
    <div
      style={{
        ...blockCSS,
        // pointerEvents: appState?.is_preview_mode ? "none" : "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {videos.length > 0 ? (
        <>
          <style jsx>{`
            .main-swiper {
              perspective: 1000px; /* Set the depth for the 3D effect */
            }

            .main-swiper > .swiper-wrapper {
              display: flex;
              align-items: center;
              transition: transform 0.5s ease; /* Smooth transition when rotating */
            }
            .main-swiper > .swiper-wrapper > .swiper-slide {
              z-index: 0;
              height: 65% !important;
              transition: height 300ms ease, width 300ms ease, z-index 0s !important;
            }

            /* Mobile View */
            ${videos.length <= 1 || isMobile
              ? `
              .main-swiper > .swiper-wrapper > .swiper-slide {
                width: 100% !important;
                height: 100% !important;
                transform: none !important;
              }
            `
              : `
              .main-swiper > .swiper-wrapper > .swiper-slide-prev {
                z-index: 0;
                height: 65% !important;
                width: 30% !important;
                transform: translateX(30%) !important; /* Add 3D rotation */
                transition: height 300ms ease, width 300ms ease, z-index 0s !important;
              }

              .main-swiper > .swiper-wrapper > .swiper-slide-prev .innnr-swiper {
                display: none;
              }

              .main-swiper > .swiper-wrapper > .swiper-slide-active {
                z-index: 2; /* Ensure the active slide is on top */
                height: 100% !important;
                width: 40% !important;
                transition: height 300ms ease, width 300ms ease, z-index 0s !important;
              }

              .main-swiper > .swiper-wrapper > .swiper-slide-next {
                z-index: 0;
                height: 65% !important;
                width: 30% !important;
                transform:  translateX(-30%) !important; /* Add 3D rotation */
                transition: height 300ms ease, width 300ms ease, z-index 0s !important;
              }

              .main-swiper > .swiper-wrapper > .swiper-slide-next .innnr-swiper {
                display: none;
              }
            `}
          `}</style>

          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination, Mousewheel]}
            centeredSlides={true}
            direction={isMobile ? "vertical" : "horizontal"}
            navigation={isMobile ? false : videos.length <= 1 ? false : true}
            slidesPerView={isMobile || videos.length <= 1 ? 1 : 3}
            mousewheel={true}
            onSlideChange={handleSlideChange}
            className="main-swiper"
            style={{
              ...blockCSS,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {section?.sections.map((section, index) => (
              <SwiperSlide
                key={index}
                style={{
                  ...blockCSS,
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  border: "none !impoartant",
                }}
                onClick={() => {
                  if (swiperRef.current && swiperRef.current.swiper) {
                    swiperRef.current.swiper.slideTo(index);
                  }
                }}
              >
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                    cursor: "grab",
                    objectFit: "cover",
                  }}
                  src={section?.src?.video}
                  title={`Video ${index + 1}`}
                  controls={section?.setting?.isControls}
                  autoPlay={index === activeIndex}
                  muted={index !== activeIndex}
                  loop={section?.setting?.isLoop}
                ></video>
                <div
                  style={{
                    position: "absolute",
                    bottom: "0",
                    padding: "10px",
                    width: "90%",
                    zIndex: "200",
                  }}
                  className="innnr-swiper"
                >
                  <Swiper
                    modules={[Pagination]}
                    slidesPerView={1}
                    spaceBetween={10}
                    style={{
                      borderRadius: "10px",
                    }}
                  >
                    {section?.products?.map((product, productIndex) => (
                      <SwiperSlide key={product.id} style={{ height: "100%" }}>
                        <div
                          key={productIndex}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            width: "100%",
                            backdropFilter: "blur(1.3)",
                            position: "relative",
                          }}
                        >
                          {/* <div
                            style={{
                              position: "absolute",
                              top: "2px",
                              right: "2px",
                            }}
                          >
                            <svg
                              width="16px"
                              height="16px"
                              viewBox="0 0 20.00 20.00"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#000000"
                              transform="matrix(-1, 0, 0, 1, 0, 0)"
                              stroke="#000000"
                              stroke-width="0.0002"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0" />
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke="#CCCCCC"
                                stroke-width="0.08"
                              />
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M10 3.778a6.222 6.222 0 1 0 3.726 11.205l-4.869-4.869v2.208a.889.889 0 0 1-1.778 0V7.968c0-.49.398-.889.89-.889h4.353a.889.889 0 0 1 0 1.778h-2.208l4.87 4.87A6.193 6.193 0 0 0 16.221 10 6.222 6.222 0 0 0 10 3.778ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"
                                  fill="#000000"
                                />
                              </g>
                            </svg>
                          </div> */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              gap: "5px",
                              textAlign: "start !important",
                            }}
                          >
                            <img
                              src={product?.image?.src}
                              alt={"product image"}
                              style={{
                                maxHeight: "100px",
                                maxWidth: "100px",
                                width: "100%",
                              }}
                            />
                            <div>
                              <p
                                style={{
                                  margin: "5px 0 0 0",
                                  textAlign: "start",
                                  color:"white"
                                }}
                              >
                                {product.title}
                              </p>
                              <div>
                                <p
                                  style={{
                                    background: "white",
                                    borderRadius: "20px",
                                    padding: "0px 10px",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    width: "fit-content",
                                  }}
                                >
                                  {currencySymbol}{" "}{product.variants[0]?.price}
                                  {/* <span
                                    style={{
                                      textDecoration: "line-through",
                                      color: "gray",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    ${product.variants[0]?.price}
                                  </span> */}
                                </p>
                              </div>
                            </div>
                          </div>
                          <button
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              border: "none",
                              cursor: "pointer",
                              padding:"8px",
                              backgroundColor: "#fff",
                            }}
                            onClick={() => window.location.href = `https://${storeName}.myshopify.com/products/${product.handle}`}
                          >
                            {isLoading ? (
                                <Loader />
                            ) : (
                              <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5px",
                              }}>
                              <p style={{
                                margin:"0px",
                              }}>
                              View Product
                              </p>
                              <img src={ViewTabIcon} alt="Product Link" style={{width:"15px",margin:"0"}}/>  
                              </div>
                            )}
                          </button>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : (
        <p>No videos available</p>
      )}
      <style jsx>{`
        .swiper-button-prev,
        .swiper-button-next {
          background-color: #414141;
          color: white !important;
          border-radius: 5px;
          padding: 10px 2px;
        }
        .swiper-button-prev:hover,
        .swiper-button-next:hover {
          background-color: #333;
        }
      `}</style>
    </div>
  );
};

export default SliderBlock;
