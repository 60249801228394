import { useEffect, useMemo, useRef } from "react";
import RenderButtonComponent from "./RenderButtonComponent";
import RenderPopupComponent from "./RenderPopupComponent";
import { TEMPLATE_TYPE } from "../../constant/constant";
import useActionHandler from "../../hooks/useActionHandler";
import { useAction } from "../../hooks/useAction";
import { useConfetti } from "../../hooks/useConfetti";
import RenderDynamicPopupPreview from "../../components/Preview/RenderDynamicPopupPreview";
import { AppProvider, useApp } from "../../context/AppState";
import { createPortal } from "react-dom";
import useEmbedding from "../../hooks/useEmbedding";
import usePageCondition from "../../hooks/usePageCondition";
import useFrequencyCondition from "../../hooks/useFrequencyCondition";
import useDeviceCondition from "../../hooks/useDeviceCondition";
import { createTheme, ThemeProvider } from "@mui/material";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

const RenderInitialPopups = ({
  popupState,
  setPopupState,
  popupData,
  loadAllPopupModel,
}) => {
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);
  const embeddingData = useMemo(
    () => popupData?.generalSettings?.condition?.embed,
    [popupData]
  );

  const { actionHandler } = useAction();
  const confetti = useConfetti(popupData);
  const { appState, setAddToCartEvents } = useApp();
  const { placeholder } = useEmbedding(embeddingData);
  const { isPopupPageTargeted } = usePageCondition();
  const { isPopupAllowedForFrequency } = useFrequencyCondition(popupData);
  const { isDesktopActive, isMobileActive } = useDeviceCondition(popupData);

  const { handleProductAddToCartSubmit } = useActionHandler(
    popupData,
    setPopupState,
    {},
    popupState
  );

  const dispatchOriginalEvent = (event) => {
    // Add our event
    const simulatedEvent = new MouseEvent(event.type, {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    simulatedEvent.__simulatedClick = true;

    event.target.dispatchEvent(simulatedEvent);
  };

  const handleOverRideAddCartFormSubmit = (event, form, showPopupKey) => {
    if (!form || !form.action?.includes("/cart/add")) {
      return dispatchOriginalEvent(event);
    }

    const productCondition = popupData?.generalSettings?.condition?.product;

    if (productCondition?.onCartAddEventPrevented) {
      setAddToCartEvents(() => () => {
        const simulatedEvent = new MouseEvent(event.type, {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        simulatedEvent.__simulatedClick = true;

        console.log("called Dispatch", event);

        event.target.dispatchEvent(simulatedEvent);
      });
    } else {
      setAddToCartEvents(() => {});
      dispatchOriginalEvent(event);
    }

    if (productCondition?.oneTimeShow) {
      localStorage.setItem(showPopupKey, "true");
    }

    handleProductAddToCartSubmit();
  };

  useEffect(() => {
    const showPopupKey = `trigger_show_cart_add_${popupType}_${popupId}`;
    const hasShownBefore = localStorage.getItem(showPopupKey);

    if (
      (hasShownBefore &&
        popupData?.generalSettings?.condition?.product?.oneTimeShow) ||
      !popupData?.generalSettings?.condition?.product?.onCartAdd
    ) {
      return;
    }

    const handleSubmit = (event) => {
      if (event.__simulatedClick) return;

      const form = event.target;
      if (!form.action?.includes("/cart/add")) return;

      if (popupData?.generalSettings?.condition?.product?.allowTrigger) {
        const tempFormData = new FormData(form);
        const formData = Object.fromEntries(tempFormData?.entries());

        const variantId = String(formData?.id);
        const conditionIds =
          popupData?.generalSettings?.condition?.product?.specific_variants ||
          [];

        if (!conditionIds.includes(variantId)) {
          return;
        }
      }

      event.preventDefault();
      event.stopImmediatePropagation();

      handleOverRideAddCartFormSubmit(event, form, showPopupKey);
    };

    const handleClick = (event) => {
      if (event.__simulatedClick) return;

      const form = event.target.closest("form");
      if (!form || !form.action?.includes("/cart/add")) return;

      event.preventDefault();
      event.stopImmediatePropagation();

      handleOverRideAddCartFormSubmit(event, form, showPopupKey);
    };

    // Attach event listeners
    document.addEventListener("submit", handleSubmit, true); // 'true' to use capturing phase for submit event
    // document.addEventListener("click", handleClick, true); // 'true' for capturing the click event before bubbling

    return () => {
      // Cleanup event listeners
      document.removeEventListener("submit", handleSubmit, true);
      // document.removeEventListener("click", handleClick, true);
    };
  }, [popupData, popupState?.openPopup, handleProductAddToCartSubmit]);

  useEffect(() => {
    const { is_embedded } = embeddingData || {};
    const { is_preview_mode } = appState || {};

    const createPreviewWrapper = () => {
      const wrapper = document.createElement("div");
      wrapper.id = "preview-wrapper";
      Object.assign(wrapper.style, {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "0",
        left: "0",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      });

      const previewContainer = document.createElement("div");
      previewContainer.id = "preview-container";
      Object.assign(previewContainer.style, {
        width: "100%",
        padding: "20px",
        border: "2px dashed lightgray",
        margin: "10px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      });
      previewContainer.textContent = "Target Container";

      wrapper.appendChild(previewContainer);
      document.body.appendChild(wrapper);
    };

    if (is_embedded && is_preview_mode) {
      if (!document.getElementById("preview-container")) {
        createPreviewWrapper();
      }
    } else {
      const wrapper = document.getElementById("preview-wrapper");
      if (wrapper) {
        wrapper.remove();
      }
    }
  }, [embeddingData, appState]);

  const isDevicePopupVisible = useMemo(
    () =>
      (isDesktopActive && !isMobileActive) ||
      (!isDesktopActive && isMobileActive) ||
      (isDesktopActive && isMobileActive),
    [isMobileActive, isDesktopActive]
  );

  const cache = createCache({
    key: "css",
    prepend: true,
    container: placeholder,
  });

  const shadowTheme = createTheme({
    components: {
      MuiPopover: {
        defaultProps: {
          container: placeholder,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: placeholder,
        },
      },
      MuiModal: {
        defaultProps: {
          container: placeholder,
        },
      },
    },
    typography: {
      h1: {
        "@media (max-width:425px)": {
          fontSize: "18px",
        },
      },
      h5: {
        "@media (max-width:425px)": {
          fontSize: "20px",
        },
      },
      subtitle1: {
        "@media (max-width:425px)": {
          fontSize: "14px",
        },
      },
      body1: {
        "@media (max-width:425px)": {
          fontSize: "12px",
        },
      },
      button: {
        "@media (max-width:425px)": {
          fontSize: "12px",
        },
      },
    },
  });

  if (!appState?.is_preview_mode) {
    if (
      popupData?.generalSettings?.condition?.product?.onCartAdd ||
      [TEMPLATE_TYPE.UPSELL_CHECKOUT].includes(popupType)
    ) {
      if (!popupState?.openPopup) {
        return null;
      }
    }
  }

  if (popupData?.generalSettings?.isDraggable) {
    if (embeddingData?.is_embedded) {
      if (!placeholder) {
        return null;
      }

      return createPortal(
        <CacheProvider value={cache}>
          <ThemeProvider theme={shadowTheme}>
            <AppProvider prevState={appState}>
              {isDevicePopupVisible &&
                isPopupPageTargeted(popupData) &&
                isPopupAllowedForFrequency && (
                  <RenderDynamicPopupPreview
                    popupData={popupData}
                    popupState={popupState}
                    setPopupState={setPopupState}
                  />
                )}
            </AppProvider>
          </ThemeProvider>
        </CacheProvider>,
        placeholder
      );
    }

    if (!popupState?.openPopup) return;

    return (
      <>
        {isDevicePopupVisible &&
          isPopupPageTargeted(popupData) &&
          isPopupAllowedForFrequency && (
            <RenderDynamicPopupPreview
              popupData={popupData}
              popupState={popupState}
              setPopupState={setPopupState}
            />
          )}
      </>
    );
  } else {
    if (embeddingData?.is_embedded) {
      if (!placeholder) {
        return null;
      }

      return createPortal(
        <CacheProvider value={cache}>
          <ThemeProvider theme={shadowTheme}>
            <AppProvider prevState={appState}>
              {popupData?.generalSettings?.is_tab &&
                !popupState?.openPopup &&
                isPopupPageTargeted(popupData) &&
                isPopupAllowedForFrequency &&
                isDevicePopupVisible &&
                !embeddingData?.is_embedded && (
                  <RenderButtonComponent
                    popupState={popupState}
                    popupData={popupData}
                    setPopupState={setPopupState}
                    onClickHandler={() => {
                      actionHandler(() => loadAllPopupModel(popupType));
                      confetti.fire();
                    }}
                  />
                )}

              {popupData &&
                popupState?.openPopup &&
                isPopupPageTargeted(popupData) &&
                isPopupAllowedForFrequency &&
                isDevicePopupVisible && (
                  <RenderPopupComponent
                    popupState={popupState}
                    setPopupState={setPopupState}
                    popupData={popupData}
                  />
                )}
            </AppProvider>
          </ThemeProvider>
        </CacheProvider>,
        placeholder
      );
    }

    return (
      <>
        {popupData?.generalSettings?.is_tab &&
          !popupState?.openPopup &&
          isPopupPageTargeted(popupData) &&
          isPopupAllowedForFrequency &&
          isDevicePopupVisible &&
          !embeddingData?.is_embedded && (
            <RenderButtonComponent
              popupState={popupState}
              popupData={popupData}
              setPopupState={setPopupState}
              onClickHandler={() => {
                actionHandler(() => loadAllPopupModel(popupType));
                confetti.fire();
              }}
            />
          )}

        {popupData &&
          popupState?.openPopup &&
          isPopupPageTargeted(popupData) &&
          isPopupAllowedForFrequency &&
          isDevicePopupVisible && (
            <RenderPopupComponent
              popupState={popupState}
              setPopupState={setPopupState}
              popupData={popupData}
            />
          )}
      </>
    );
  }
};

export default RenderInitialPopups;
