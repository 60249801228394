import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./context/AppState";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

// Import Swiper styles
import swiperBaseStyles from "swiper/css";
import swiperNavigationStyles from "swiper/css/navigation";
import swiperPaginationStyles from "swiper/css/pagination";

export let shadowRootElement;
export let shadowRootElemenTag;

function injectStylesIntoShadowRoot(shadowRoot) {
  // Create link element to inject Swiper styles
  const linkElement = document.createElement("link");
  linkElement.rel = "stylesheet";
  linkElement.href = "https://unpkg.com/swiper/swiper-bundle.min.css"; // Swiper CDN link
  shadowRoot.appendChild(linkElement);

  // Optionally, inject additional custom Swiper styles here if necessary
  const styleElement = document.createElement("style");
  styleElement.textContent = `
    ${JSON.stringify(swiperBaseStyles)}
    ${JSON.stringify(swiperNavigationStyles)}
    ${JSON.stringify(swiperPaginationStyles)}
  `;
  shadowRoot.appendChild(styleElement);
}

function initializeApp(shadowRootContainer) {
  let shadowRoot;
  let reactRootElement;

  if (window.isLiveModeTrigger) {
    shadowRoot = shadowRootContainer.shadowRoot;

    if (!shadowRoot) {
      shadowRoot = shadowRootContainer.attachShadow({ mode: "open" });
    }

    reactRootElement = shadowRoot.querySelector("#popup-preview-root");
    if (!reactRootElement) {
      reactRootElement = document.createElement("div");
      reactRootElement.id = "popup-preview-root";
      shadowRoot.appendChild(reactRootElement);
    }
    injectStylesIntoShadowRoot(shadowRoot);
  } else {
    reactRootElement = document.querySelector("#popup-preview-root");
    if (!reactRootElement) {
      reactRootElement = document.createElement("div");
      reactRootElement.id = "popup-preview-root";
      document.body.appendChild(reactRootElement);
    }
    injectStylesIntoShadowRoot(document.body);
  }

  if (reactRootElement) {
    const shadowReactRoot = ReactDOM.createRoot(reactRootElement);
    shadowRootElement = shadowRoot || document.body;
    shadowRootElemenTag = reactRootElement;

    const cache = createCache({
      key: "css",
      prepend: true,
      container: shadowRootElement,
    });

    const shadowTheme = createTheme({
      components: {
        MuiPopover: {
          defaultProps: {
            container: shadowRootElemenTag,
          },
        },
        MuiPopper: {
          defaultProps: {
            container: shadowRootElemenTag,
          },
        },
        MuiModal: {
          defaultProps: {
            container: shadowRootElemenTag,
          },
        },
      },
      typography: {
        h1: {
          "@media (max-width:425px)": {
            fontSize: "18px",
          },
        },
        h5: {
          "@media (max-width:425px)": {
            fontSize: "20px",
          },
        },
        subtitle1: {
          "@media (max-width:425px)": {
            fontSize: "14px",
          },
        },
        body1: {
          "@media (max-width:425px)": {
            fontSize: "12px",
          },
        },
        button: {
          "@media (max-width:425px)": {
            fontSize: "12px",
          },
        },
      },
    });

    shadowReactRoot.render(
      <React.StrictMode>
        <BrowserRouter>
          <CacheProvider value={cache}>
            <ThemeProvider theme={shadowTheme}>
              <AppProvider>
                <App />
              </AppProvider>
            </ThemeProvider>
          </CacheProvider>
        </BrowserRouter>
      </React.StrictMode>
    );
  }
}

function startObserver() {
  const shadowRootContainer = document.querySelector("#popup-preview-shadow-root");
  if (shadowRootContainer) {
    console.log("#popup-preview-shadow-root already exists");
    shadowRootContainer.style.zIndex = "9999999999999";
    initializeApp(shadowRootContainer);
    return;
  }

  const observer = new MutationObserver((mutationsList) => {
    console.log("Observer triggered");

    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        console.log("Mutation detected:", mutation);
        const latestShadowRootContainer = document.querySelector("#popup-preview-shadow-root");

        if (latestShadowRootContainer) {
          console.log("#popup-preview-shadow-root found");
          observer.disconnect();
          latestShadowRootContainer.style.zIndex = "9999999999999";
          initializeApp(latestShadowRootContainer);
          break;
        }
      }
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
}

startObserver();

reportWebVitals();
