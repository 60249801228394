import React from "react";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";

const CustomHtmlBlock = ({ section }) => {

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    position: "relative",
  };

  return (
    <div
      style={blockCSS}
      dangerouslySetInnerHTML={{ __html: section?.content || "" }}
    />
  );
};

export default CustomHtmlBlock;
