import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import {
  DEFAULT_BLOCK_CSS,
  POPUP_SECTIONS,
} from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import getSymbolFromCurrency from "currency-symbol-map";
import { parseCssStringToObject } from "../../../../helper";

const PriceBlock = ({ productData, section, index, productIndex }) => {
  const { isMobile, appState } = useApp();
  const { watch } = useFormContext();

  // Watch selected variant and quantity from React Hook Form
  const selectedVariantId = watch(
    `${POPUP_SECTIONS.VARIANT_PICKER}-${productIndex}`
  );

  // Find the selected variant from product data
  const selectedVariant = productData?.variants?.find(
    (variant) => variant.id === Number(selectedVariantId)
  );

  const price = selectedVariant?.price;
  const compare_at_price = selectedVariant?.price;

  const currency = appState?.is_preview_mode
    ? getSymbolFromCurrency(appState?.shop?.currency)
    : getSymbolFromCurrency(window?.Shopify?.currency?.active);

  const dynamicData = {
    currency,
    price,
    compare_at_price,
  };
  
  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const priceText = useMemo(() => {
    return (section?.content || "").replace(
      /{{\s*([^{}\s]+)\s*}}/g,
      (_, key) => dynamicData?.[key] || ""
    );
  }, [section, dynamicData]);

  return (
    <div style={blockCSS} dangerouslySetInnerHTML={{ __html: priceText }} />
  );
};

PriceBlock.propTypes = {
  productData: PropTypes.shape({
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  section: PropTypes.object,
};

PriceBlock.defaultProps = {
  section: {},
};

export default memo(PriceBlock);
