import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useApp } from "../../../../context/AppState";
import { parseCssStringToObject } from "../../../../helper";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";

const VariantPickerBlock = ({ section, productIndex, productData }) => {
  const { control, setValue } = useFormContext();
  const { isMobile } = useApp();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(productData?.variants?.[0]);
  const [searchTerm, setSearchTerm] = useState("");

  const name = `${section?.type}-${productIndex}`;

  useEffect(() => {
    setSelected(productData?.variants?.[0]);
    setValue(
      name,
      productData?.variants?.[0]?.id ??
        productData?.variants?.[0]?.admin_graphql_api_id?.split("/")?.pop()
    );
  }, [productData]);

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const dropdownHeaderStyle = {
    ...blockCSS,
    padding: "0px 10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background-color 0.2s ease, color 0.2s ease",
    ...(isOpen && {
      backgroundColor: blockCSS?.backgroundColor ?? "#f0f0f0",
      color: blockCSS?.color ?? "#000000",
    }),
  };

  const dropdownListStyle = {
    borderRadius: 0,
    borderBottomLeftRadius: blockCSS.borderRadius,
    borderBottomRightRadius: blockCSS.borderRadius,
    position: "absolute",
    backgroundColor: "#FFFFFF",
    zIndex: 9999,
    width: "100%",
    maxHeight: "200px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    left: 0,
    top: "100%",
    overflowY: "auto",
    transition: "opacity 0.2s ease, transform 1s ease",
  };

  const dropdownItemStyle = {
    ...blockCSS,
    textAlign: "start",
    borderRadius: "0px",
    justifyContent: "start",
    border: "none",
    padding: "10px",
    cursor: "pointer",
    transition:
      "background-color 0.3s ease, opacity 0.3s ease, transform 0.2s ease",
  };

  const dropdownSearchStyle = {
    ...dropdownItemStyle,
    textAlign: "start",
    backgroundColor: "transparent",
  };

  const noDataStyle = {
    ...dropdownItemStyle,
    textAlign: "center",
    cursor: "default",
  };

  const handleSelect = (variant) => {
    setSelected(variant);
    setIsOpen(false);
  };

  const ArrowIcon = ({ isOpen }) => (
    <svg
      fill={blockCSS?.color ?? "#000000"}
      width={blockCSS?.fontSize ? `${parseInt(blockCSS.fontSize) - 5}` : "10px"}
      height={
        blockCSS?.fontSize ? `${parseInt(blockCSS.fontSize) - 5}` : "10px"
      }
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isOpen ? (
        <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z" />
      ) : (
        <path d="M11.178 4.431a.998.998 0 0 0-1.644 0l-9 13A.999.999 0 0 0 3 19h18a1.002 1.002 0 0 0 .822-1.569l-9-13z" />
      )}
    </svg>
  );

  const filteredVariants = productData?.variants?.filter((variant) =>
    variant.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {productData?.variants.length > 1 && (
        <Controller
          name={name}
          control={control}
          defaultValue={
            productData?.variants?.[0]?.id ??
            productData?.variants?.[0]?.admin_graphql_api_id?.split("/")?.pop()
          }
          render={({ field }) => (
            <div
              key={isOpen}
              style={{
                ...blockCSS,
                ...(isOpen && {
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                }),
              }}
            >
              <div
                style={dropdownHeaderStyle}
                onClick={() => setIsOpen(!isOpen)}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "88%",
                    display: "inline-block",
                  }}
                >
                  {selected.title || "Select Variant"}
                </span>
                <ArrowIcon isOpen={isOpen} />
              </div>

              {isOpen && (
                <div style={dropdownListStyle}>
                  {filteredVariants?.length > 5 && (
                    <input
                      type="text"
                      placeholder={section?.search?.placeholder ?? "Search..."}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={dropdownSearchStyle}
                    />
                  )}
                  {filteredVariants?.length > 0 ? (
                    filteredVariants.map((variant, idx) => (
                      <div
                        key={idx}
                        style={dropdownItemStyle}
                        onMouseOver={(e) => {
                          e.target.style.backgroundColor = "#fff";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor =
                            section?.setting.customCSS?.desktop?.backgroundColor;
                        }}
                        onClick={() => {
                          handleSelect(variant);
                          field.onChange(
                            variant?.id ??
                              variant?.admin_graphql_api_id?.split("/")?.pop()
                          );
                        }}
                      >
                        {variant.title}
                      </div>
                    ))
                  ) : (
                    <div style={noDataStyle}>No Data</div>
                  )}
                </div>
              )}
            </div>
          )}
        />
      )}
    </>
  );
};

export default VariantPickerBlock;
