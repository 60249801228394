import React, { memo } from "react";
import { parseCssStringToObject } from "../../../../helper";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import { Controller, useFormContext } from "react-hook-form";

const SubscriptionBlock = ({ section, productIndex }) => {
  const { isMobile } = useApp();
  const { control } = useFormContext();

  const name = `${section?.type}-${productIndex}`;

  const containerCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const subscriptionCSS = {
    ...parseCssStringToObject(
      section?.setting?.subscription_css?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const activeSubscriptionCSS = {
    ...parseCssStringToObject(
      section?.setting?.active_subscription_css?.[
        isMobile ? "mobile" : "desktop"
      ]
    ),
  };

 
  return (
    <div style={containerCSS}>
      {section?.subscriptions?.map((subscription, index) => (
        <Controller
          name={name}
          control={control}
          defaultValue={section?.default_selected}
          render={({ field }) => (
            <>
              <input
                {...field}
                defaultChecked={
                  section?.default_selected === subscription?.value
                }
                type="radio"
                hidden
                id={`${section?.type}-${productIndex}-${index}`}
                value={subscription?.value}
                style={{
                  height:
                    section?.setting?.customCSS?.[
                      isMobile ? "mobile" : "desktop"
                    ]?.fontSize,
                  width:
                    section?.setting?.customCSS?.[
                      isMobile ? "mobile" : "desktop"
                    ]?.fontSize,
                }}
              />
              <label
                style={{
                  ...subscriptionCSS,
                  ...(field.value === subscription.value &&
                    activeSubscriptionCSS),
                }}
                htmlFor={`${section?.type}-${productIndex}-${index}`}
                dangerouslySetInnerHTML={{
                  __html: subscription?.content ?? "",
                }}
              />
            </>
          )}
        />
      ))}
    </div>
  );
};

export default memo(SubscriptionBlock);
