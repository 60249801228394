import React, { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import { sentPostMessage } from "../../helper";
import { useApp } from "../../context/AppState";

const DraggableWrapper = ({
  popupSettings,
  lockDraggable,
  section,
  children,
  isSelected,
  onClick,
  index,
  isSubSection,
  subIndex,
}) => {
  const { appState, isMobile } = useApp();

  const [updateDisplacement, setUpdateDisplacement] = useState(null);
  const [updateDimension, setUpdateDimension] = useState(null);
  const [updateLayer, setUpdateLayer] = useState(null);

  const [resizing, setResizing] = useState(false);
  const [dragging, setDragging] = useState(false);

  const [currentDisplacement, setCurrentDisplacement] = useState(null);
  const [currentDimension, setCurrentDimension] = useState(null);
  const [currentLayer, setCurrentLayer] = useState(null);

  useEffect(() => {
    setCurrentDisplacement(null);
    setUpdateDisplacement(null);
    setCurrentDimension(null);
    setUpdateDimension(null);
    setCurrentLayer(null);
    setUpdateLayer(null);
  }, [section, isMobile]);

  const position = useMemo(
    () =>
      currentDisplacement ??
      section?.setting?.position?.displacement?.[
        isMobile ? "mobile" : "desktop"
      ] ?? { x: 0, y: 0 },
    [currentDisplacement, section, isMobile]
  );

  const dimension = useMemo(
    () =>
      currentDimension ??
      section?.setting?.dimension?.[isMobile ? "mobile" : "desktop"] ?? {
        width: 100,
        height: 100,
      },
    [currentDimension, section, isMobile]
  );

  const layer = useMemo(
    () =>
      currentLayer ??
      section?.setting?.layer?.[isMobile ? "mobile" : "desktop"] ?? {
        zIndex: 1,
      },
    [currentLayer, section, isMobile]
  );

  const transformOrigin = useMemo(
    () => ({
      x: position?.x + dimension?.width / 2,
      y: position?.y + dimension?.height / 2,
    }),
    [position, dimension]
  );

  const handleDrag = (e, data) => {
    if (!resizing && !lockDraggable) {
      setCurrentDisplacement({ x: data.x, y: data.y });
    }
  };

  const handleDragStop = (e, data) => {
    if (!resizing && !lockDraggable) {
      setUpdateDisplacement({ x: data.x, y: data.y });
      setDragging(false);
    }
  };

  const handleResize = (e, direction) => {
    e.stopPropagation();
    setResizing(true);
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = dimension?.width;
    const startHeight = dimension?.height;
    const startPosition = position;

    let newWidth = startWidth;
    let newHeight = startHeight;
    let newX = startPosition?.x;
    let newY = startPosition?.y;

    const onMouseMove = (event) => {
      const dx = event.clientX - startX;
      const dy = event.clientY - startY;

      switch (direction) {
        case "top-left":
          newWidth = startWidth - dx;
          newHeight = startHeight - dy;
          if (!isSubSection) {
            newX = startPosition?.x + dx;
            newY = startPosition?.y + dy;
          }
          break;
        case "bottom-left":
          newWidth = startWidth - dx;
          newHeight = startHeight + dy;
          if (!isSubSection) {
            newX = startPosition?.x + dx;
          }
          break;
        case "top-right":
          newWidth = startWidth + dx;
          newHeight = startHeight - dy;
          if (!isSubSection) {
            newY = startPosition?.y + dy;
          }
          break;
        case "bottom-right":
          newWidth = startWidth + dx;
          newHeight = startHeight + dy;
          break;
        case "left":
          newWidth = startWidth - dx;
          if (!isSubSection) {
            newX = startPosition?.x + dx;
          }
          break;
        case "right":
          newWidth = startWidth + dx;
          break;
        case "top":
          newHeight = startHeight - dy;
          if (!isSubSection) {
            newY = startPosition?.y + dy;
          }
          break;
        case "bottom":
          newHeight = startHeight + dy;
          break;
        default:
          break;
      }

      setCurrentDimension({
        width: Math.max(newWidth, 20),
        height: Math.max(newHeight, 20),
      });
      setCurrentDisplacement({ x: newX, y: newY });
    };

    const onMouseUp = () => {
      setUpdateDimension({
        width: Math.max(newWidth, 20),
        height: Math.max(newHeight, 20),
      });
      setUpdateDisplacement({ x: newX, y: newY });

      setResizing(false);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  useEffect(() => {
    if (updateDisplacement) {
      sentPostMessage("displacement", {
        type: section?.type,
        index,
        displacement: updateDisplacement,
        isSubSection,
        subIndex,
      });
    }
  }, [updateDisplacement]);

  useEffect(() => {
    if (updateDimension) {
      sentPostMessage("dimension", {
        type: section?.type,
        index,
        dimension: updateDimension,
        isSubSection,
        subIndex,
      });
    }
  }, [updateDimension]);

  useEffect(() => {
    if (updateLayer) {
      sentPostMessage("layer", {
        type: section?.type,
        index,
        layer: updateLayer,
        isSubSection,
        subIndex,
      });
    }
  }, [updateLayer]);

  const [isLayerPopoverOpen, setIsLayerPopoverOpen] = useState(false);

  const popoverRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsLayerPopoverOpen(false);
    }
  };

  useEffect(() => {
    if (isLayerPopoverOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [isLayerPopoverOpen]);

  return (
    <>
      <Draggable
        key={`${resizing}-${index}-${subIndex}`}
        position={position}
        onStart={(e) => {
          e.stopPropagation();
          if (!lockDraggable) setDragging(true);
        }}
        onStop={handleDragStop}
        onDrag={handleDrag}
        disabled={resizing || !isSelected || lockDraggable}
      >
        <div
          style={{
            position: "absolute",
            ...layer,
            ...dimension,
            transformOrigin: `${transformOrigin?.x}px ${transformOrigin?.y}px`,
            cursor: dragging
              ? "move"
              : !appState?.is_preview_mode
              ? "pointer"
              : "inherit",
          }}
          onMouseEnter={(e) => {
            if (window?.isLiveModeTrigger) return;
            const targetOverlay =
              e.currentTarget.querySelector(".hover-overlay");
            if (targetOverlay) {
              targetOverlay.style.opacity = 1;
            }
          }}
          onMouseLeave={(e) => {
            if (window?.isLiveModeTrigger) return;
            const targetOverlay =
              e.currentTarget.querySelector(".hover-overlay");
            if (targetOverlay) {
              targetOverlay.style.opacity = 0;
            }
          }}
        >
          {!window?.isLiveModeTrigger && (
            <div
              className="hover-overlay"
              style={{
                position: "absolute",
                zIndex: 9999,
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                backgroundColor: "rgba(92, 67, 159, 0.2)",
                border: "2px solid #3A2C6F",
                transition: "all 0.3s ease-in-out",
                pointerEvents: "none",
              }}
            />
          )}
          <div
            onClick={onClick}
            style={{
              width: "100%",
              height: "100%",
              color: "transparent",
            }}
          >
            {children}
          </div>
        </div>
      </Draggable>
      {isSelected && (
        <div
          style={{
            position: "absolute",
            zIndex: 10,
            ...dimension,
            transform: `translate(${position?.x || 0}px, ${
              position?.y || 0
            }px)`,
            transformOrigin: `${transformOrigin?.x}px ${transformOrigin?.y}px`,
            border: "2px solid #5c439f",
            pointerEvents: "none",
          }}
        >
          {!lockDraggable && (
            <>
              <div
                style={handleStyle("top-left")}
                onMouseDown={(e) => handleResize(e, "top-left")}
              />
              <div
                style={handleStyle("top-right")}
                onMouseDown={(e) => handleResize(e, "top-right")}
              />
              <div
                style={handleStyle("bottom-left")}
                onMouseDown={(e) => handleResize(e, "bottom-left")}
              />
              <div
                style={handleStyle("bottom-right")}
                onMouseDown={(e) => handleResize(e, "bottom-right")}
              />
              <div
                style={edgeHandleStyle("left")}
                onMouseDown={(e) => handleResize(e, "left")}
              />
              <div
                style={edgeHandleStyle("right")}
                onMouseDown={(e) => handleResize(e, "right")}
              />
              <div
                style={edgeHandleStyle("top")}
                onMouseDown={(e) => handleResize(e, "top")}
              />
              <div
                style={edgeHandleStyle("bottom")}
                onMouseDown={(e) => handleResize(e, "bottom")}
              />

              <div style={toolbarStyle}>
                <div
                  onClick={() =>
                    sentPostMessage("duplicate", {
                      type: section?.type,
                      index,
                      subIndex,
                    })
                  }
                  style={iconButtonStyle}
                  title="Duplicate"
                >
                  <svg
                    fill="#000000"
                    viewBox="0 0 1920 1920"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <svg
                        fill="#000000"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path d="M4 22h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2zm2-9h3v-3h2v3h3v2h-3v3H9v-3H6v-2z"></path>
                          <path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"></path>
                        </g>
                      </svg>
                    </g>
                  </svg>
                </div>

                <div
                  onClick={() =>
                    sentPostMessage("delete", {
                      type: section?.type,
                      index,
                      subIndex,
                    })
                  }
                  style={iconButtonStyle}
                  title="Delete"
                >
                  <svg
                    fill="#000000"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z"></path>
                    </g>
                  </svg>
                </div>
                <div
                  onClick={() => setIsLayerPopoverOpen((prev) => !prev)}
                  style={iconButtonStyle}
                  title="Layers"
                >
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M17.4 10L21 12L17.4 14M17.4 10L12 13L6.6 10M17.4 10L21 8L12 3L3 8L6.6 10M6.6 10L3 12L6.6 14M17.4 14L21 16L12 21L3 16L6.6 14M17.4 14L12 17L6.6 14"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>

                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {isLayerPopoverOpen && (
                      <div
                        ref={popoverRef}
                        style={{
                          position: "absolute",
                          top: "-80%",
                          left: "180%",
                          width: "max-content",
                          background: "#FFFFFF",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.8)",
                          borderRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        {[
                          {
                            label: "Bring forward",
                            zIndex: 2,
                            icon: (
                              <svg
                                fill="#000000"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="30%"
                                  y="30%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "rgb(115 115 115)",
                                  }}
                                />
                                <rect
                                  x="10%"
                                  y="10%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "#5c439f",

                                    // stroke: "#fff",
                                    // strokeWidth: "2",
                                  }}
                                />
                              </svg>
                            ),
                          },
                          {
                            label: "Bring to front",
                            zIndex: 3,
                            icon: (
                              <svg
                                fill="#000000"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="30%"
                                  y="30%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "rgb(115 115 115)",
                                  }}
                                />
                                <rect
                                  x="20%"
                                  y="20%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "rgb(181 181 181)",
                                  }}
                                />
                                <rect
                                  x="10%"
                                  y="10%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "#5c439f",
                                  }}
                                />
                              </svg>
                            ),
                          },
                          {
                            label: "Send backward",
                            zIndex: 1,
                            icon: (
                              <svg
                                fill="#000000"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="30%"
                                  y="30%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "#5c439f",
                                  }}
                                />
                                <rect
                                  x="10%"
                                  y="10%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "rgb(115 115 115)",
                                  }}
                                />
                              </svg>
                            ),
                          },
                          {
                            label: "Send to back",
                            zIndex: 0,
                            icon: (
                              <svg
                                fill="#000000"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="30%"
                                  y="30%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "#5c439f",
                                  }}
                                />
                                <rect
                                  x="20%"
                                  y="20%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "rgb(181 181 181)",
                                  }}
                                />
                                <rect
                                  x="10%"
                                  y="10%"
                                  width="50%"
                                  height="50%"
                                  rx="2"
                                  style={{
                                    fill: "rgb(125 125 125)",
                                  }}
                                />
                              </svg>
                            ),
                          },
                        ].map((layer, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              padding: "10px 15px",
                              cursor: "pointer",
                              fontSize: "15px",
                              color: "#000000",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setCurrentLayer({ zIndex: layer.zIndex });
                              setUpdateLayer({ zIndex: layer.zIndex });
                              setIsLayerPopoverOpen((prev) => !prev);
                            }}
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "rgba(0, 0, 0, 0.05)")
                            }
                            onMouseLeave={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "transparent")
                            }
                          >
                            <div style={{ width: "25px", height: "25px" }}>
                              {layer.icon}
                            </div>
                            <div
                              style={{
                                fontSize: "18px",
                              }}
                            >
                              {layer.label}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const iconButtonStyle = {
  width: "24px",
  height: "24px",
  color: "black",
  cursor: "pointer",
  transition: "transform 0.2s ease",
  display: "inline-block",
  "&:hover": {
    transform: "scale(1.1)",
  },
};

const handleStyle = (position) => ({
  position: "absolute",
  width: "8px",
  height: "8px",
  backgroundColor: "white",
  border: "1px solid black",
  borderRadius: "50%",
  pointerEvents: "auto",
  cursor: getCursorForHandle(position),
  ...handlePositionStyle(position),
});

const edgeHandleStyle = (position) => ({
  position: "absolute",
  width: position === "left" || position === "right" ? "4px" : "20px",
  height: position === "top" || position === "bottom" ? "4px" : "20px",
  backgroundColor: "white",
  borderRadius: "20px",
  border: "1px solid black",
  pointerEvents: "auto",
  cursor: getCursorForEdge(position),
  ...handlePositionStyle(position),
});

const getCursorForHandle = (position) => {
  switch (position) {
    case "top-left":
    case "bottom-right":
      return "nwse-resize";
    case "top-right":
    case "bottom-left":
      return "nesw-resize";
    default:
      return "default";
  }
};

const getCursorForEdge = (position) => {
  switch (position) {
    case "left":
    case "right":
      return "ew-resize";
    case "top":
    case "bottom":
      return "ns-resize";
    default:
      return "default";
  }
};

const handlePositionStyle = (position) => {
  switch (position) {
    case "top-left":
      return { top: "-4px", left: "-4px" };
    case "top-right":
      return { top: "-4px", right: "-4px" };
    case "bottom-left":
      return { bottom: "-4px", left: "-4px" };
    case "bottom-right":
      return { bottom: "-4px", right: "-4px" };
    case "left":
      return { top: "50%", left: "-4px", transform: "translateY(-50%)" };
    case "right":
      return { top: "50%", right: "-4px", transform: "translateY(-50%)" };
    case "top":
      return { top: "-4px", left: "50%", transform: "translateX(-50%)" };
    case "bottom":
      return { bottom: "-4px", left: "50%", transform: "translateX(-50%)" };
    default:
      return {};
  }
};

const toolbarStyle = {
  position: "absolute",
  top: "-50px",
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: "#fff",
  padding: "5px 10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  borderRadius: "5px",
  display: "flex",
  gap: "15px",
  pointerEvents: "auto",
};

const rotateContainerStyle = {
  position: "absolute",
  top: "-100px",
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: "#fff",
  padding: "5px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  borderRadius: "100%",
  display: "flex",
};

export default DraggableWrapper;
