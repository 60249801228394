import React, { useState, useEffect } from "react";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import {
  calculateEventTimeDifference,
  parseCssStringToObject,
} from "../../../../helper";

const CountdownBlock = ({ popupData, section }) => {

  const { isMobile, appState } = useApp();

  const { isHideDays, isHideseconds, isResetTimer, isCustomLabel } =
    popupData?.generalSettings?.condition?.countdown?.conditions || {};

  const { countdown_type } =
    popupData?.generalSettings?.condition?.countdown || {};

  const {days,hours,minutes,seconds}= 
    popupData?.generalSettings?.condition?.countdown?.values || {};

  const { countdownEndTime } =
    popupData?.generalSettings?.condition?.countdown?.values || {};

  const [endTime, setEndTime] = useState(null);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
      if (countdown_type === "moving_date") {
        const now = new Date();
        const calculatedEndTime = new Date(
          now.getTime() +
            (days || 0) * 86400000 +
            (hours || 0) * 3600000 +
            (minutes || 0) * 60000 +
            (seconds || 0) * 1000
        );
        setEndTime(calculatedEndTime);
      } else {
        setEndTime(new Date(countdownEndTime));
      }
  }, [popupData?.generalSettings?.condition]);

  const updateTimeLeft = () => {
    const now = Date.now();
    const totalSeconds = Math.floor((endTime - now) / 1000);
    if (totalSeconds <= 0) {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      return;
    }

    let totalHours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let days = 0;
    let hours = totalHours;

    if (!isHideDays) {
      days = Math.floor(totalHours / 24);
      hours = totalHours % 24;
    }

    setTimeLeft({ days, hours, minutes, seconds });
  };

  useEffect(() => {
      const timer = setInterval(() => {
        updateTimeLeft();
      }, 500);
      return () => clearInterval(timer);
  }, [endTime]);

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCSS?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const timerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const separatorStyle = {
    margin: "0 5px",
  };

  return (
    <>
      <div style={blockCSS}>
        {!isHideDays && (
          <>
            <div style={timerStyle}>
              <span>{String(timeLeft.days).padStart(2, "0")}</span>
              <span>
                {isCustomLabel
                  ? popupData?.generalSettings?.condition?.countdown?.values
                      ?.dayText
                  : "Days"}
              </span>
            </div>
            <span style={separatorStyle}>:</span>
          </>
        )}
        <div style={timerStyle}>
          <span>{String(timeLeft.hours).padStart(2, "0")}</span>
          <span>
            {isCustomLabel
                  ? popupData?.generalSettings?.condition?.countdown?.values
                      ?.hourText
                  : "Hours"}
          </span>
        </div>
        <span style={separatorStyle}>:</span>
        <div style={timerStyle}>
          <span>{String(timeLeft.minutes).padStart(2, "0")}</span>
          <span>
            {isCustomLabel
                  ? popupData?.generalSettings?.condition?.countdown?.values
                      ?.minuteText
                  : "Minutes"}
          </span>
        </div>
        {!isHideseconds && (
          <>
            <span style={separatorStyle}>:</span>
            <div style={timerStyle}>
              <span>{String(timeLeft.seconds).padStart(2, "0")}</span>
              <span>
                {isCustomLabel
                  ? popupData?.generalSettings?.condition?.countdown?.values
                      ?.secondText
                  : "Seconds"}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CountdownBlock;
