import { useMemo, useState } from "react";
import { useSubscribers } from "./useSubscribers";
import { useApp } from "../context/AppState";
import { useConfetti } from "./useConfetti";
import { analyticsEventTypes, getBlock } from "../helper";
import { useAnalytics } from "./useAnalytics";
import { usePopup } from "./usePopup";
import { POPUP_SECTIONS } from "../constant/constant";
import { defaultDraggablePopupStates, defaultPopupStates } from "../pages/popup/DefaultPopupStates";

const useActionHandler = (
  popupData = {},
  setPopupState = () => { },
  fields = {},
  popupState
) => {
  const { birthday, email, text } = fields;
  const { appState, dispatch, setUpsellData } = useApp();
  const { fetchRecommendedProduct } = usePopup();
  const confetti = useConfetti(popupData);
  const { createSubscribers } = useSubscribers(popupData);
  const { addAnalyticsEvent } = useAnalytics();
  const [isEmptyField, setIsEmptyField] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [disableSpin, setDisableSpin] = useState(false);

  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);
  const isDraggable = useMemo(
    () => popupData?.generalSettings?.isDraggable,
    [popupData?.generalSettings?.isDraggable]
  );

  const handleClose = () => {
    setPopupState((prev) => {
      if (isDraggable) {
        return {
          ...prev,
          [`${popupType}_${popupId}`]: { ...defaultDraggablePopupStates[popupType] },
        };
      } else {
        return {
          ...prev,
          [`${popupType}_${popupId}`]: { ...defaultPopupStates[popupType] },
        }
      };
    });
  };

  const onGetCouponCode = async () => {
    const result = getBlock(popupData, popupState?.activeStep, "BIRTHDAY")?.text
      ? Object.keys(birthday).some((field) => !birthday[field]) || !email
      : !email;
    setIsEmptyField(result);

    if (result || !email) return;

    const _r = await createSubscribers(email, appState?.shop?.shop, birthday);

    if (_r?.data?.email) {
      await addAnalyticsEvent({
        eventType: analyticsEventTypes.INTERACTION,
        popupType: popupData?.popUpType,
        popupId: popupData?._id,
        ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
      });

      setIsEmailExist(false);
      setPopupState((prev) => ({
        ...prev,
        [`${popupType}_${popupId}`]: {
          ...prev[`${popupType}_${popupId}`],
          activeStep: prev[`${popupType}_${popupId}`]?.activeStep + 1,
        },
      }));
      confetti.fire();
    } else {
      setIsEmailExist(true);
    }
  };

  const onSubmitFeedBack = async () => {
    setIsEmptyField(!email || !text);
    if (isEmptyField || !email || !text) return;
    const _r = await createSubscribers(email, appState?.shop?.shop, text);
    if (_r?.data?.email) {
      await addAnalyticsEvent({
        eventType: analyticsEventTypes.INTERACTION,
        popupType: popupData?.popUpType,
        popupId: popupData?._id,
        ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
      });

      setIsEmailExist(false);
      setPopupState((prev) => {
        return {
          ...prev,
          [`${popupType}_${popupId}`]: {
            ...prev[`${popupType}_${popupId}`],
            activeStep: 1,
          },
        };
      });
      confetti.fire();
    } else {
      setIsEmailExist(true);
    }
  };

  const onSpinStart = async () => {
    setIsEmptyField(!email);
    if (isEmptyField || !email) return;
    const _r = await createSubscribers(email, appState?.shop?.shop, text);
    if (_r?.data?.email) {
      await addAnalyticsEvent({
        eventType: analyticsEventTypes.INTERACTION,
        popupType: popupData?.popUpType,
        popupId: popupData?._id,
        ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
      });

      setSpinning(true);
      setDisableSpin(true);
      setIsEmailExist(false);
    } else {
      setIsEmailExist(true);
    }
  };

  const onSpinFinished = async (winner) => {
    const { Reward_code, title } = winner;

    dispatch({
      type: "SPIN_WHEEL",
      payload: {
        Reward_code,
      },
    });
    localStorage.setItem("Winner", title);
    localStorage.setItem(
      "WheelCouponCode",
      JSON.stringify({
        email,
        coupon: title,
      })
    );
    setPopupState((prev) => {
      return {
        ...prev,
        [`${popupType}_${popupId}`]: {
          ...prev[`${popupType}_${popupId}`],
          activeStep: 1,
        },
      };
    });
    confetti.fire();
  };

  const handleProductAddToCartSubmit = async () => {

    if (popupData?.generalSettings?.condition?.product?.onCartAdd) {
      setPopupState((prev) => {
        return {
          ...prev,
          [`${popupType}_${popupId}`]: {
            ...prev[`${popupType}_${popupId}`],
            openPopup: true,
          },
        };
      });

      if (!popupState.openPopup) {
        confetti.fire();
      }
      await addAnalyticsEvent({
        eventType: analyticsEventTypes.OPEN,
        popupType: popupData?.popUpType,
        popupId: popupData?._id,
        ...(popupData?.campaign_Id && {
          campaignId: popupData?.campaign_Id,
        }),
      });
    } 

  };

  return {
    handleClose,
    onGetCouponCode,
    onSubmitFeedBack,
    isEmptyField,
    isEmailExist,
    setIsEmailExist,
    onSpinStart,
    onSpinFinished,
    spinning,
    setSpinning,
    disableSpin,
    setDisableSpin,
    handleProductAddToCartSubmit,
  };
};

export default useActionHandler;
