import { useMemo } from "react";
import { useApp } from "../context/AppState";
import { analyticsEventTypes } from "../helper";
import { useAnalytics } from "./useAnalytics";
import { useSubscribers } from "./useSubscribers";
import { useFormContext } from "react-hook-form";
import { useConfetti } from "./useConfetti";
import { POPUP_SECTIONS } from "../constant/constant";
import cartService from "../services/cart.service";

export const useFormSumbmit = ({ popupData, setPopupState, productIndex, productData, handleClose }) => {
    const { appState, toast } = useApp();
    const { watch } = useFormContext();
    const popupValue = watch();
    const { addAnalyticsEvent } = useAnalytics();
    const popupType = useMemo(() => popupData?.popUpType, [popupData]);
    const popupId = useMemo(() => popupData?._id, [popupData]);

    const confetti = useConfetti(popupData);

    const { createSubscribers } = useSubscribers(popupData);

    const getCouponCode = async () => {


        const response = await createSubscribers(popupValue?.email, appState?.shop?.shop, popupValue?.birthdate, popupValue?.textarea, popupValue?.rating);


        if (response?.success) {

            if (response?.data?.isExist) {
                return toast.error("Email is already exist!");
            }

            confetti.fire();

            await addAnalyticsEvent({
                eventType: analyticsEventTypes.INTERACTION,
                popupType: popupData?.popUpType,
                popupId: popupData?._id,
                ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
            });

            setPopupState((prev) => ({
                ...prev,
                [`${popupType}_${popupId}`]: { ...prev[`${popupType}_${popupId}`], activeStep: prev[`${popupType}_${popupId}`]?.activeStep + 1 },
            }));
        } else {
            toast.error("Email is already exist!");
        }
    };

    const upgradeOrder = async (formData) => {
        const { selling_plan } = formData;

        const selling_plan_radio_value = popupValue?.[`${POPUP_SECTIONS.SUBSCRIPTION}-${productIndex}`];

        try {

            let items = [
                {
                    id: popupValue?.[`${POPUP_SECTIONS.VARIANT_PICKER}-${productIndex}`] ?? productData?.variants?.[0]?.id ??
                        productData?.variants?.[0]?.admin_graphql_api_id?.split("/")?.pop(),
                    quantity: popupValue?.[`${POPUP_SECTIONS.QUANTITY_PICKER}-${productIndex}`] ?? 1,
                    selling_plan: selling_plan_radio_value ? Number(selling_plan_radio_value) : (selling_plan ? Number(selling_plan) : ''),
                    // properties: { source: "Ordered by Aim Ai Popup Upsell" },
                },
            ];

            const result = await cartService.addToCart(items);

            if (result) {
                await addAnalyticsEvent({
                    eventType: analyticsEventTypes.INTERACTION,
                    popupType: popupData?.popUpType,
                    popupId: popupData?._id,
                    ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
                });
            }

        } catch (error) {
            console.error(error);
        } finally {
            handleClose(false);
            setTimeout(() => {
                window.location.href = "/cart";
            }, 100);
        }

    };

    const addCart = async (formData) => {
        const { selling_plan } = formData;

        const selling_plan_radio_value = popupValue?.[`${POPUP_SECTIONS.SUBSCRIPTION}-${productIndex}`];

        try {

            let items = [
                {
                    id: popupValue?.[`${POPUP_SECTIONS.VARIANT_PICKER}-${productIndex}`] ?? productData?.variants?.[0]?.id ??
                        productData?.variants?.[0]?.admin_graphql_api_id?.split("/")?.pop(),
                    quantity: popupValue?.[`${POPUP_SECTIONS.QUANTITY_PICKER}-${productIndex}`] ?? 1,
                    selling_plan: selling_plan_radio_value ? Number(selling_plan_radio_value) : (selling_plan ? Number(selling_plan) : ''),
                    // properties: { source: "Ordered by Aim Ai Popup Upsell" },
                },
            ];

            const result = await cartService.addToCart(items);

            if (result) {
                await addAnalyticsEvent({
                    eventType: analyticsEventTypes.INTERACTION,
                    popupType: popupData?.popUpType,
                    popupId: popupData?._id,
                    ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
                });
            }

        } catch (error) {
            console.error(error);
        } finally {

            handleClose();
        }

    };

    const checkout = async (formData) => {
        const { selling_plan } = formData;

        const selling_plan_radio_value = popupValue?.[`${POPUP_SECTIONS.SUBSCRIPTION}-${productIndex}`];

        try {

            let items = [
                {
                    id: popupValue?.[`${POPUP_SECTIONS.VARIANT_PICKER}-${productIndex}`] ?? productData?.variants?.[0]?.id ??
                        productData?.variants?.[0]?.admin_graphql_api_id?.split("/")?.pop(),
                    quantity: popupValue?.[`${POPUP_SECTIONS.QUANTITY_PICKER}-${productIndex}`] ?? 1,
                    selling_plan: selling_plan_radio_value ? Number(selling_plan_radio_value) : (selling_plan ? Number(selling_plan) : ''),
                    // properties: { source: "Ordered by Aim Ai Popup Upsell" },
                },
            ];

            const result = await cartService.getCheckoutUrl(items);

            if (result) {
                await addAnalyticsEvent({
                    eventType: analyticsEventTypes.INTERACTION,
                    popupType: popupData?.popUpType,
                    popupId: popupData?._id,
                    ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
                });

                window.location.href = result?.data?.data?.cart?.checkoutUrl;
            }
        } catch (error) {
            console.error(error);
        }
    };



    return {
        upgradeOrder,
        getCouponCode,
        addCart,
        checkout
    };
};